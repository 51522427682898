
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

.c-common-anonymous-homepage {
    text-align: left;
    width: 100%;
    background-color: $nu-white;

    &__hero-container {
        height: calc(100vh - var(--headerOffset));
    }

    &__section-label,
    &__section-heading {
        display: block;
    }

    &__section-label {
        margin-bottom: $nu-spacer-0pt5;
    }

    &__section-heading {
        margin-bottom: $nu-spacer-1pt5;
    }

    &__section-body {
        margin-bottom: $nu-spacer-4;
    }

    &__section {
        padding: $nu-spacer-12 $nu-spacer-2;

        @include breakpoint(medium) {
            padding: $nu-spacer-16 $nu-spacer-5;
        }

        @include breakpoint(large) {
            padding: $nu-spacer-16 $nu-spacer-8;
        }

        &--row1,
        &--row2 {
            background-color: $nu-secondary;
        }
    }
}
