
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-home-hero {
        $this: &;

        height: 52vh;
        min-height: 500px;
        width: 100%;
        position: relative;

        @include breakpoint(medium) {
            height: 58vh;
        }

        &__hero-media {
            object-fit: cover;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }

        &__copy-container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
        }

        &__headline {
            @include title-3;
            margin-bottom: $nu-spacer-1;

            @include breakpoint(medium) {
                @include title-1;
                margin-bottom: $nu-spacer-1pt5;
            }

            &--primary {
                color: $nu-primary;
            }

            &--secondary {
                color: $nu-secondary;
            }

            &--white {
                color: $nu-white;
            }

            &--black {
                color: $nu-black;
            }
         }

         &__body-copy {
            @include body-copy;

            @include breakpoint(medium) {
                margin-bottom: $nu-spacer-4;
            }

            &--primary {
                color: $nu-primary;
            }

            &--secondary {
                color: $nu-secondary;
            }

            &--white {
                color: $nu-white;
            }

            &--black {
                color: $nu-black;
            }
         }

        &__button {
            position: absolute;
            bottom: $nu-spacer-4;
            left: 0;
            right: 0;
            margin: auto;

            @include breakpoint(medium) {
                position: static;
                margin: 0;
            }

            &--primary-dark {
                &:hover,
                &:focus,
                &:active {
                    background-color: $nu-white !important;
                    border-color: $nu-primary !important;
                    color: $nu-primary !important;
                }
            }

            &--primary-light {
                &:hover,
                &:focus,
                &:active {
                    background-color: $transparent !important;
                    border-color: $nu-white !important;
                    color: $nu-white !important;
                }
            }

            &--secondary-dark {
                &:hover,
                &:focus,
                &:active {
                    background-color: $nu-white !important;
                }
            }

            &--secondary-light {
                &:hover,
                &:focus,
                &:active {
                    background-color: $nu-white !important;
                    border-color: $nu-primary !important;
                    color: $nu-primary !important;
                }
            }
        }
    }
