
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

.c-home-social-grid-row {
    background-color: $nu-white;
    padding: $nu-spacer-10 0;

    @include breakpoint(large) {
        padding: $nu-spacer-16 0;
    }

    &__header {
        text-align: center;
    }

    &__body {
        margin-bottom: $nu-spacer-5;

        @include breakpoint(medium) {
            margin-bottom: $nu-spacer-8;
        }
    }
}
