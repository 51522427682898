
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

.c-anonymous-home-row-one {
    background-color: $nu-secondary;
    padding: $nu-spacer-12 0;

    @include breakpoint(medium) {
        padding: $nu-spacer-16 0;
    }

    @include breakpoint(large) {
        padding: $nu-spacer-16 0;
    }

    &__two-up {
        padding-left: $nu-spacer-2;
        padding-right: $nu-spacer-2;

        @include breakpoint(medium) {
            padding-left: $nu-spacer-5;
            padding-right: $nu-spacer-5;
        }

        @include breakpoint(large) {
            padding-left: $nu-spacer-8;
            padding-right: $nu-spacer-8;
        }
    }
}
