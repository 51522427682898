
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-homepage-rec-tray {
        &__text {
            text-align: center;
            padding: 0 $nu-spacer-2;
            margin: 0 $nu-spacer-2;

            @include breakpoint(small) {
                padding: 0;
                margin: 0 $nu-spacer-4;
            }

            @include breakpoint(large) {
                margin: 0 $nu-spacer-8;
            }
        }
    }
