
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .home-carousel {
        &__text {
            text-align: center;
            margin-bottom: $nu-spacer-5;
        }

        &__section-heading {
            margin-bottom: $nu-spacer-1pt5;
        }
    }
