
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

.c-common-anonymous-homepage-content-row-2 {
    &__plan {
        margin-bottom: $nu-spacer-8;

        @include breakpoint(medium) {
            margin-bottom: $nu-spacer-12;
        }
    }

    &__heading {
        margin-bottom: $nu-spacer-3;

        @include breakpoint(medium) {
            margin-bottom: $nu-spacer-2;
        }
    }

    &__carousel {
        @include breakpoint(large) {
            display: none;
        }
    }
}
