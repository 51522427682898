
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

.c-home-one-up {
    padding: $nu-spacer-10 0;
    background-color: $nu-secondary;

    @include breakpoint(large) {
        padding: $nu-spacer-16 0;
    }

    &__container {
        align-items: center;
    }

    &__logo {
        width: 167px;
        height: 32px;
        fill: $nu-primary;

        svg {
            width: 100%;
            max-width: 100%;
        }
    }

    &__image {
        margin-bottom: $nu-spacer-4;

        @include breakpoint(medium) {
            margin-bottom: 0;
        }
    }
}
